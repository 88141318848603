import { Button, Container, useToast } from '@aurecon-creative-technologies/styleguide'
import React, { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { createUpdateScenarioApi } from 'src/api/ScenarioServices'
import LoadingScreen from 'src/components/LoadingScreen'
import { getCurrentDateISOString } from 'src/helpers/utils'
import { ScenarioDefaultValues } from 'src/models/ScenarioDefaultValues'
import { CapxValuesInterface } from 'src/models/ScenarioFormData'
import {
  formDataState,
  formStepState,
  inProductedSecretState,
  projectDataSelector,
  scenarioListSelector,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../styles/CreateScenario.module.sass'
import { ScenarioStepLine } from './scenario/ScenarioStepLine'
import { validateStepData } from './scenario/ScenarioValidationSchemas'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { validateScenarioName } from 'src/api/ScenarioService'
const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7']

const CreateScenario: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Scenario' })
  const successToast = 'Scenario updated successfully'
  const errorToast = 'Failed to create Scenario'
  const showToast = useToast()
  const SecretState = useRecoilValue(inProductedSecretState)
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [selectedProjectId] = useRecoilState(selectedProjectIdState)
  const [step, setStep] = useRecoilState(formStepState)
  const [formData, setFormData] = useRecoilState(formDataState)
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(scenarioListSelector)
  const projectData = useRecoilValueLoadable(projectDataSelector)

  const [errors, setErrors] = useState<Record<string, string>>({})
  const navigate = useNavigate()

  const addCreateScenarioCapexInput = useCallback(
    (year_of_deployment: number, first_year_of_commercial_operation: number) => {
      const yearDeployment = Number(year_of_deployment)
      const firstCommercialOperation = Number(first_year_of_commercial_operation)
      const totalRowsToAdd = firstCommercialOperation - yearDeployment

      if (!isNaN(totalRowsToAdd)) {
        const updatedCreateCapexData: CapxValuesInterface[] = []

        for (let i = 0; i < totalRowsToAdd; i++) {
          const totalYears = yearDeployment + i
          if (totalYears < firstCommercialOperation) {
            const newCapexObj: CapxValuesInterface = {
              created_at: getCurrentDateISOString(),
              updated_at: getCurrentDateISOString(),
              created_by: getCurrentDateISOString(),
              updated_by: getCurrentDateISOString(),
              operating_year: i,
              year: totalYears,
              capex_estimate: 0,
              comments: '',
              bess_estimate: 0,
              re_generator_estimate: 0,
              common_equipment: 0,
            }
            updatedCreateCapexData.push(newCapexObj)
          }
        }

        setFormData((prevData) => ({
          ...prevData,
          capex: updatedCreateCapexData,
        }))
      }
    },
    [setFormData],
  )

  const nextStep = async () => {
    const stepErrors = await validateStepData(step, formData)

    if (stepErrors) {
      setErrors(stepErrors)
      return
    }

    setErrors({})

    if (step === 1) {
      try {
        const generatingProfileId =
          Number(formData?.scenario_project_type) === 1 ? null : Number(formData?.generating_profile_id)
        const hybridGenerationType =
          Number(formData?.scenario_project_type) === 1 ? null : Number(formData?.hybrid_generation_type)

        const payload = {
          name: formData?.name,
          project_life_years: Number(formData?.project_life_years),
          forecast_data_id: Number(formData?.forecast_data_id),
          generating_profile_id: generatingProfileId,
          project_type: Number(formData?.scenario_project_type),
          hybrid_generation_type: hybridGenerationType,
          sampling_interval: formData?.time_res,
        }

        const response = await validateScenarioName(payload)

        if (response.status === 500 || response.status === 422) {
          handleResponseToast(errorToast + '-' + response?.['detail'], 'error', 3000)
          return
        } else if (response.status === 400 || response.status === 401 || response.status === 404) {
          handleResponseToast(errorToast + '\n' + response?.['detail'], 'error', 5000)
        } else {
          addCreateScenarioCapexInput(
            Number(formData.year_of_deployment),
            Number(formData.first_year_of_commercial_operation),
          )
          setStep((prevStep) => Math.min(prevStep + 1, 7))
        }
      } catch (error) {
        console.error('Error validating report:', error)
      }
    } else if (step === 2) {
      setStep((prevStep) => Math.min(prevStep + 1, 7))
    } else {
      setStep((prevStep) => Math.min(prevStep + 1, 7))
    }
  }

  const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1))

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    setLoadingText('Saving data')

    try {
      const response = await createUpdateScenarioApi('create', '', { ...formData, projectID: selectedProjectId })
      console.log('status', response.status)
      if (response.success || response.status === 200 || response.status === 201) {
        handleResponseToast(successToast, 'success', 3000)
        setFormData(ScenarioDefaultValues)
        setLoading(false)
        setLoadingText('')
        setStep(1)
        refreshFetchItems()
        navigate(`/dashboard/${selectedProjectId}/scenario`)
      } else if (response.message === 'Internal Server Error' || response.status === 500) {
        handleResponseToast(errorToast + '-' + response.message, 'error', 3000)
      } else {
        handleResponseToast(errorToast + '-' + response.message, 'error', 3000)
      }
    } catch (error) {
      handleResponseToast(errorToast, 'error', 3000)
    } finally {
      setLoading(false)
      setLoadingText('')
    }
  }

  const handleGoBack = () => {
    setFormData(ScenarioDefaultValues)
    navigate(`/dashboard/${selectedProjectId}/scenario`)
  }
  const handleResponseToast = (
    message: string,
    toastType: 'success' | 'error' | 'info' | 'warning',
    timeout: number,
  ) => {
    showToast.addToast({
      message: message,
      type: toastType,
      timeout: timeout,
    })
  }

  React.useEffect(() => {
    setStep(1)
    setFormData(ScenarioDefaultValues)
    if (projectData && projectData.contents['items'] !== undefined) {
      const projectType = projectData.contents['items'].filter(
        (item: { id: number; project_type: number }) => item.id === selectedProjectId,
      )
      if (projectType && projectType.length > 0) {
        setFormData({ ...ScenarioDefaultValues, scenario_project_type: projectType[0].project_type })
      }
    }
  }, [projectData, selectedProjectId, setFormData, setStep])

  return (
    <div>
      {loading ? (
        <LoadingScreen text={loadingText} />
      ) : (
        <>
          <div className={Style.mainBlock}>
            <div className={Style.goBack}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                onClick={handleGoBack}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
              />
              <h2 className={Style.titleHead}>Create Scenarios</h2>
            </div>
            {SecretState && !isRoleEmpty(SecretState.role) && (
              <div className={Style.stepBtn}>
                <Button
                  onClick={prevStep}
                  disabled={!loading ? step === 1 : loading}
                  type='secondary'
                  size='medium'
                  label='Previous'
                  cssClass={Style.btnPrevious}
                />
                <Button
                  type='secondary'
                  label={step === steps.length ? 'Create' : 'Next'}
                  flexible
                  onClick={step === steps.length ? handleSubmit : nextStep}
                  disabled={loading}
                  cssClass={Style.btnNext}
                  size='medium'
                />
              </div>
            )}
          </div>
          <Container fluid cssClass={Style.paddingX}>
            <ScenarioStepLine
              step={step}
              steps={steps}
              formType={false}
              updateFormData={formData}
              setUpdateFormData={setFormData}
              error={errors}
              hideLines={true}
            />
          </Container>
        </>
      )}
    </div>
  )
}

export default CreateScenario
