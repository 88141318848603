import { Button, Container, Grid, Text, useToast } from '@aurecon-creative-technologies/styleguide'
import { ChartConfiguration, ChartData, Point } from 'chart.js'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { appInsights } from 'src/api/AppInsights'
import { downloadReportPDFFile, fetchReportDetial, uploadReportPDFAsMultipart } from 'src/api/ReportService'
import LoadingScreen from 'src/components/LoadingScreen'
import SafeHtml from 'src/components/SafeHtml'
import { AverageContingencyFCASPriceChart } from 'src/components/charts/AverageContingencyFCASPriceChart'
import AverageRegulationFCASPriceChart from 'src/components/charts/AverageRegulationFCASPriceChart'
import FCASRevenueChart from 'src/components/charts/FCASRevenueChart'
import PreferredScenarioResultsChart from 'src/components/charts/PreferedScenarioResultsChart'
import SampleDayFCASPriceChart from 'src/components/charts/SampleDayFCASPriceChart'
import WholesaleArbitragePriceSpreadChart from 'src/components/charts/WholesaleArbitragePriceSpreadchart'
import WholesaleEnergyPriceChart from 'src/components/charts/WholesaleEnergyPriceChart'
import { ReportDetailResponeModal } from 'src/models/api/ReportDetailResponeModal'
import Style from '../../styles/Reports.module.sass'
import ViewCommercialAndMarketAssemptions from './Table/ViewCommercialAndMarketAssemptions'
import ViewCommercialPerformance from './Table/ViewCommercialPerformance'
import ViewScenarioSummary from './Table/ViewScenarioSummary'
import ViewTechnicalAssumptions from './Table/ViewTechnicalAssumptions'
import SampleStateChargeProfilesChart from 'src/components/charts/SampleStateChargeProfilesChart'
import SampleDispatchProfilesChart from 'src/components/charts/SampleDispatchProfilesChart'
import BatteryMarketDispatchStateChargeProfile from 'src/components/charts/BatteryMarketDispatchStateChargeProfile'
import AverageEnergyChart from 'src/components/charts/AverageEnergyChart'

interface CumulativeDataItem {
  x: string
  y: [number, number]
}

interface WholesaleArbitragePriceSpreadChartItem {
  labels: string[]
  datasets: {
    label: string
    data: number[]
  }[]
}
interface AverageContingencyFCASPriceChartItem {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    borderColor: string
    backgroundColor: string
    borderWidth: number
    tension: number
    yAxisID: string
  }[]
}
interface BatteryMarketDispatchStateChargeProfileItem {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    borderColor: string
    backgroundColor: string
    tension: number
    yAxisID: string
    fill: boolean
  }[]
}
interface ProcessChartDataItem {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    backgroundColor: string
  }[]
}

const ReportDetails = () => {
  if (appInsights) {
    appInsights.trackPageView({ name: 'Report Detail' })
  }
  const params = useParams()
  const showToast = useToast()
  const componentRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [isInitialRender, setIsInitialRender] = useState(true)
  const report_Id = params.report_Id
  const [reportData, setReportData] = useState<ReportDetailResponeModal>()
  const [preferedScenarioChart, setPreferedScenarioChart] = useState<CumulativeDataItem[]>()
  const [wholesaleEnergyChart, setWholesaleEnergyChart] = useState<ChartData<'line'>[]>()
  const [averageDailyEnergyChart, setAverageDailyEnergyChart] = useState<ChartData<'line'>[]>()
  const [wholesaleArbitragePriceSpreadChart, setWholesaleArbitragePriceSpreadChart] =
    useState<WholesaleArbitragePriceSpreadChartItem>()
  type LineChartData = ChartData<'line', (number | Point | null)[], unknown>

  const [averageRegulationFCASPriceChart, setAverageRegulationFCASPriceChart] = useState<LineChartData>()
  const [averageContingencyFCASPriceChart, setAverageContingencyFCASPriceChart] =
    useState<AverageContingencyFCASPriceChartItem>()
  const [sampleDayFCASPriceChart, setSampleDayFCASPriceChart] = useState<ChartConfiguration<'line'>['data']>()
  const [fcasRevenueChart, setFCASRevenueChart] = useState<ProcessChartDataItem>()
  const [sampleStateChargeProfilesChart, setSampleStateChargeProfilesChart] = useState<LineChartData>()
  const [sampleDispatchProfilesChart, setSampleDispatchProfilesChart] = useState<LineChartData>()
  const [batteryMarketDispatchStateChargeProfile, setBatteryMarketDispatchStateChargeProfile] =
    useState<BatteryMarketDispatchStateChargeProfileItem>()

  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState<number>(0)
  const [reportFilePath, setReportFilePath] = useState(null)
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)

  useEffect(() => {
    const fetchDetails = async () => {
      if (!report_Id) return
      try {
        setLoading(true)
        setLoadingText('Fetching data')

        const response = await fetchReportDetial(Number(report_Id))
        if (response?.success === false) {
          showToast.addToast({ type: 'error', message: 'Report details not found!', timeout: 3000 })
          return
        }
        if (response) {
          setReportData(response as keyof object)
          setReportFilePath(response?.['report_file_path'])
        }
      } catch (err) {
        console.error('Error fetching or processing report details:', err)
        showToast.addToast({ type: 'error', message: 'Failed to fetch report details!', timeout: 3000 })
      } finally {
        setLoading(false)
      }
    }

    if (isInitialRender) {
      fetchDetails()
      setIsInitialRender(false)
    }
  }, [report_Id, isInitialRender, showToast, reportData])

  useEffect(() => {
    const getParsedDataBySectionId = (sectionId: number) => {
      const section = reportData?.report_detail?.report_section.find((section) => section.section_id === sectionId)
      return section?.chart_data ? JSON.parse(section?.chart_data) : ''
    }
    const getParsedDataBySectionIdForMultiple = (sectionId: number, chartName: string) => {
      const section = reportData?.report_detail?.report_section.find((section) => section.section_id === sectionId)
      if (section === undefined) return
      const chartObj = section?.chart_data ? JSON.parse(section.chart_data) : {}
      const chartData = chartObj?.[chartName]
      if (typeof chartData === 'string') {
        try {
          return JSON.parse(chartData)
        } catch (e) {
          console.error('Failed to parse chart data:', e)
          return ''
        }
      } else {
        return chartData || ''
      }
    }
    setPreferedScenarioChart(getParsedDataBySectionId(2))
    setWholesaleEnergyChart(getParsedDataBySectionId(6))
    setWholesaleArbitragePriceSpreadChart(getParsedDataBySectionId(7))
    setAverageDailyEnergyChart(getParsedDataBySectionId(8))
    setAverageRegulationFCASPriceChart(getParsedDataBySectionIdForMultiple(9, 'chart1'))
    setAverageContingencyFCASPriceChart(getParsedDataBySectionIdForMultiple(9, 'chart2'))
    setSampleDayFCASPriceChart(getParsedDataBySectionIdForMultiple(8, 'chart3'))
    setFCASRevenueChart(getParsedDataBySectionId(10))
    setSampleStateChargeProfilesChart(getParsedDataBySectionIdForMultiple(11, 'chart1'))
    setSampleDispatchProfilesChart(getParsedDataBySectionIdForMultiple(12, 'chart1'))
    setBatteryMarketDispatchStateChargeProfile(getParsedDataBySectionIdForMultiple(13, 'chart3'))
  }, [reportData?.report_detail?.report_section])

  const renderFormattedHtml = (section_id: string) => {
    const htmlString =
      reportData?.report_detail.report_section.find((section) => section.section_id === Number(section_id))
        ?.formatted_comment || ''

    return <SafeHtml html={htmlString} />
  }

  const onUploadProgress = (progress: number) => {
    setProgressPercentage(progress)
  }

  const handleUploadPDF = async (componentRef: React.RefObject<HTMLElement>) => {
    try {
      if (!report_Id) return
      setDownloadButtonLoading(true)

      const pdfFile = await generatePDF(componentRef)
      const formData = new FormData()
      formData.append('report_file', pdfFile)
      const uploadResponse = await uploadReportPDFAsMultipart(
        report_Id?.toString(),
        formData,
        pdfFile,
        onUploadProgress,
      )
      if (uploadResponse?.success === false) {
        showToast.addToast({ type: 'error', message: 'Failed to upload PDF!', timeout: 3000 })
        return
      } else {
        showToast.addToast({ type: 'success', message: 'PDF generated successfully!', timeout: 3000 })
        setReportFilePath(uploadResponse?.['report_file_path'])
      }
    } catch (error) {
      console.error('Error handling PDF download and upload:', error, progressPercentage)
    } finally {
      setDownloadButtonLoading(false)
    }
  }

  const generatePDF = async (componentRef: React.RefObject<HTMLElement>) => {
    try {
      if (!componentRef.current) {
        throw new Error('Component reference is null or undefined.')
      }
      if (!reportData) {
        throw new Error('Report data is null or undefined.')
      }
      const element = componentRef.current
      const customWidth = 12 * 25.4
      const customHeight = 18 * 25.4
      const pdf = new jsPDF('l', 'mm', [customHeight, customWidth])
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const margin = 10
      const createCanvas = async (section: HTMLElement): Promise<HTMLCanvasElement> => {
        await new Promise((resolve) => setTimeout(resolve, 100))
        const canvas = await html2canvas(section, {
          useCORS: true,
          scale: 2.5,
          logging: true,
          backgroundColor: null,
        })
        return canvas
      }
      const sections: HTMLElement[] = Array.from(element.querySelectorAll('.print-page'))
      if (sections.length === 0) {
        throw new Error('No sections found to capture.')
      }
      for (const section of sections) {
        const canvas = await createCanvas(section)
        const imgData = canvas.toDataURL('image/png', 1)
        const imgWidth = pdfWidth - margin * 2
        const imgHeight = (canvas.height * imgWidth) / canvas.width

        pdf.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight, undefined, 'FAST')
        pdf.addPage()
      }
      pdf.deletePage(pdf.getNumberOfPages())
      const pdfBlob = pdf.output('blob')
      const pdfFile = new File([pdfBlob], `${reportData?.name}-${reportData.preferred_date}.pdf`, {
        type: 'application/pdf',
      })
      pdf.save(`${reportData?.name}.pdf`)
      return pdfFile
    } catch (error) {
      console.error('Error generating Report:', error)
      throw error
    }
  }

  const handleDownloadClick = async () => {
    try {
      if (!report_Id) return
      await downloadReportPDFFile(report_Id.toString(), (progress) => {
        setDownloadProgress(progress)
      })
      setDownloadProgress(null)
    } catch (error) {
      console.error('Failed to download Report:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  return (
    <Container fluid cssClass={`${Style.paddingX} ${Style.pageBtm}`}>
      {loading ? (
        <LoadingScreen text={loadingText} />
      ) : (
        <>
          <section>
            <div className={Style.btn_container}>
              {!reportFilePath ? (
                <Button
                  type='primary'
                  cssClass={Style.btnGenerate}
                  label='Download Report'
                  size='small'
                  onClick={() => handleUploadPDF(componentRef)}
                  loading={downloadButtonLoading}
                />
              ) : (
                <Button
                  type='custom'
                  cssClass={Style.btnGenerate}
                  label='Download Report'
                  size='small'
                  onClick={() => handleDownloadClick()}
                />
              )}
            </div>
          </section>
          <section className={Style.overview_scenario} ref={componentRef}>
            <section className='page-content print-page' id='1'>
              <h3 className={Style.reportSubtitle}>Scenario overview</h3>
              <h5>
                <strong>Introductory Comment </strong>
              </h5>
              <h5>
                {reportData?.report_detail.report_section.find((section) => section.section_id === 1)?.general_comment}
              </h5>
              {reportData && (
                <>
                  <ViewScenarioSummary scenarioData={reportData?.report_detail?.report_scenario_mapping} />
                  <ViewCommercialPerformance scenarioData={reportData.report_detail.report_scenario_mapping} />
                </>
              )}
            </section>

            <section className={`${Style.chart_section} print-page chart-container`} id='2'>
              <h4>Preferred scenario results</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} cssClass={Style.chartirrcontainer}>
                  <div className={Style.chart_container}>
                    {preferedScenarioChart && (
                      <PreferredScenarioResultsChart cumulativeData={preferedScenarioChart} pageType='report' />
                    )}
                  </div>
                  <div className={Style.scenarioirr}>
                    <Text type='h2'>Scenario irr</Text>
                    <Text type='h1'>
                      {parseFloat(
                        reportData?.report_detail.report_scenario_mapping[0].internal_rate_of_return || '0',
                      ).toFixed(2)}{' '}
                      %
                    </Text>
                    <Text type='caption'>{reportData?.report_detail?.report_scenario_mapping[0]?.scenario?.name}</Text>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {reportData && (
                    <div>
                      <h2>{reportData?.report_detail.report_section[1].general_comment}</h2>
                      {renderFormattedHtml('2')}
                    </div>
                  )}
                </Grid>
              </Grid>
            </section>

            <section className={`${Style.chart_section} print-page chart-container`} id='3'>
              <h4>Commercial and market assumptions</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.table_container}>
                    {reportData && <ViewCommercialAndMarketAssemptions formData={reportData} />}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('3')}
                </Grid>
              </Grid>
            </section>

            <section className={`${Style.chart_section} print-page chart-container`} id='4'>
              <h4>Technical assumptions </h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.table_container}>
                    {reportData && <ViewTechnicalAssumptions data={reportData?.report_detail.technical_assumptions} />}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('4')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='5'>
              <h4>Network support service details </h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {renderFormattedHtml('5')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='6'>
              <h4>Wholesale energy price shape</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {wholesaleEnergyChart && <WholesaleEnergyPriceChart chartsData={wholesaleEnergyChart} />}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('6')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='7'>
              <h4>Wholesale Arbitrage Price Spread</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {wholesaleArbitragePriceSpreadChart && (
                      <WholesaleArbitragePriceSpreadChart data={wholesaleArbitragePriceSpreadChart} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('7')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='8'>
              <h4>Average Daily Energy Dispatch</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {averageDailyEnergyChart && <AverageEnergyChart chartsData={averageDailyEnergyChart} />}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('8')}
                  {sampleDayFCASPriceChart && <SampleDayFCASPriceChart data={sampleDayFCASPriceChart} />}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='9'>
              <h4>Wholesale energy price shape</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {averageRegulationFCASPriceChart && (
                      <AverageRegulationFCASPriceChart data={averageRegulationFCASPriceChart} />
                    )}
                    {averageContingencyFCASPriceChart && (
                      <AverageContingencyFCASPriceChart chartData={averageContingencyFCASPriceChart} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('9')}
                  {sampleDayFCASPriceChart && <SampleDayFCASPriceChart data={sampleDayFCASPriceChart} />}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='10'>
              <h4>FCAS Revenues</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {fcasRevenueChart && <FCASRevenueChart chartData={fcasRevenueChart} />}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('10')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='11'>
              <h4>Sample State of Charge Profile</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {sampleStateChargeProfilesChart && (
                      <SampleStateChargeProfilesChart data={sampleStateChargeProfilesChart} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('11')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='12'>
              <h4>Sample State of Charge Profile</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {sampleDispatchProfilesChart && <SampleDispatchProfilesChart data={sampleDispatchProfilesChart} />}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('12')}
                </Grid>
              </Grid>
            </section>
            <section className={`${Style.chart_section} print-page chart-container`} id='13'>
              <h4>Battery market dispatch and State of Charge profile</h4>
              <Grid row gap={12}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className={Style.chart_container}>
                    {batteryMarketDispatchStateChargeProfile && (
                      <BatteryMarketDispatchStateChargeProfile chartData={batteryMarketDispatchStateChargeProfile} />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  {renderFormattedHtml('13')}
                </Grid>
              </Grid>
            </section>
          </section>
        </>
      )}
    </Container>
  )
}

export default ReportDetails
