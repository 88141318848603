import { Grid, Table, TableRow, TableCell } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/CreateScenario.module.sass'
import { CommercialParameters } from 'src/models/ScenarioDefaultValues'
import {
  QuarterCapVolume,
  ProjectInput,
  CapexValue,
  RepexValue,
  BessExpectedPerformanceCyclePerDay,
  ProjectInputLgc,
  SipsContract,
  SrasContract,
  VcasContract,
  InertiaContract,
  NonMarket,
  NmsTableHeader,
  QuarterCapVolumeStep7,
} from 'src/enums/ScenarioEnums'
import { ScenarioFormData } from 'src/models/ScenarioFormData'
import { useRecoilValue } from 'recoil'
import { forecastDataSelector } from 'src/stores/AppStore'
import { useEffect, useState } from 'react'
import { FileProcessingStatus } from 'src/models/api/Forecasting'
import { ForecastInfo } from 'src/models/api/ForecastModalResponse'

const quarterCapVolumeHeaders = [
  { label: QuarterCapVolume.YEAR },
  { label: QuarterCapVolume.Q1_CAP_VOLUME },
  { label: QuarterCapVolume.Q1_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q1_STRIKE_PRICE },
  { label: QuarterCapVolume.Q2_CAP_VOLUME },
  { label: QuarterCapVolume.Q2_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q2_STRIKE_PRICE },
  { label: QuarterCapVolume.Q3_CAP_VOLUME },
  { label: QuarterCapVolume.Q3_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q3_STRIKE_PRICE },
  { label: QuarterCapVolume.Q4_CAP_VOLUME },
  { label: QuarterCapVolume.Q4_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q4_STRIKE_PRICE },
]

const capexValueHeaders = [{ label: CapexValue.CAPEX }, { label: CapexValue.VALUE }]
const capexValueSummaryHeadersHybrid = [
  { label: CapexValue.CAPEX },
  { label: CapexValue.BESS },
  { label: CapexValue.REGENERATOR },
  { label: CapexValue.COMMONEQUIPMENT },
]
const repexValueHeaders = [{ label: RepexValue.REPEX }, { label: ProjectInput.VALUE }]
const bessExpectedPerformanceHeaders = [
  { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
  { label: BessExpectedPerformanceCyclePerDay.ENERGYDISCHARGECAPACITYRETENTION },
  { label: BessExpectedPerformanceCyclePerDay.RTE },
]

const projectCommercialParametersHeaders = [
  { label: ProjectInput.COMMERCIAL_PARAMETERS },
  { label: ProjectInput.VALUE },
]

const sipsContractHeaders = [
  { label: SipsContract.OPERATING_YEAR },
  { label: SipsContract.SIPS_RESERVED_POWER_CAPACITY },
  { label: SipsContract.SIPS_RESERVED_ENERGY_STORAGE_CAPACITY },
  { label: SipsContract.SIPS_RESERVED_POWER_CAPACITY },
]

const srasContractHeaders = [
  { label: SrasContract.OPERATING_YEAR },
  { label: SrasContract.SRAS_RESERVED_ENERGY_STORAGE_CAPACITY },
  { label: SrasContract.SRAS_CONTRACT_VALUE },
]

const vcasContractHeaders = [
  { label: VcasContract.OPERATING_YEAR },
  { label: VcasContract.REAL_POWER_CAPABILITY_VCAS },
  { label: VcasContract.VCAS_CONTRACT_VALUE },
]

const inertiaContractHeaders = [
  { label: InertiaContract.OPERATING_YEAR },
  { label: InertiaContract.CONTRACTED_INERTIA_VALUE },
  { label: InertiaContract.CONTRACTED_INERTIA_CONTRACT_PRICE },
]

const nonMarketReservationHeaders = [
  { label: NonMarket.OPERATING_YEAR },
  { label: NonMarket.DISCHARGE_POWER_RESERVATION },
  { label: NonMarket.CHARGE_POWER_RESERVATION },
  { label: NonMarket.ENERGY_RESERVATION },
]

const getCoupledTypeLabel = (type: string): string => {
  switch (type) {
    case '1':
      return 'AC'
    case '2':
      return 'DC'
    default:
      return ''
  }
}
const getHybridGenerationTypeLabel = (type: string): string => {
  switch (type) {
    case '1':
      return 'Wind'
    case '2':
      return 'Solar'
    default:
      return ''
  }
}

const nmsTableHeader = [{ label: NmsTableHeader.OPERATING_YEAR }, { label: NmsTableHeader.NMSVALUE }]

const getLabel = (isManualInput: boolean, calculatedLabel: string, manualLabel: string): string => {
  return isManualInput ? calculatedLabel : manualLabel
}

interface Step7Props {
  formData: ScenarioFormData
  setFormData?: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors?: Record<string, string>
}

export const Step7: React.FC<Step7Props> = ({ formData }) => {
  const fetchForecastData = useRecoilValue(forecastDataSelector)
  const [forecastList, setForecastList] = useState<{ id: string; label: string }[]>([])
  useEffect(() => {
    if (fetchForecastData['items'] !== undefined) {
      const forecastFormatted = fetchForecastData['items']
        .filter((status: FileProcessingStatus) => status['file_processing_status'].name === 'SUCCESSFUL')
        .map((item: ForecastInfo) => ({
          id: item.id.toString(),
          label: item.file_name,
        }))
      setForecastList(forecastFormatted)
    }
  }, [fetchForecastData])
  return (
    <div className='mainContainerBlock'>
      <h2 className={Style.heading}>Scenario Summary</h2>
      <Grid row gap={12}>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Scenario Name:</b> {formData.name}{' '}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Associated Forecast File </b>{' '}
            {forecastList
              .filter((item) => item.id === formData.forecast_data_id)
              .map((item) => (
                <span key={item.id}>{item.label}</span>
              ))}
          </p>
        </Grid>
        {String(formData.scenario_project_type) === '2' && (
          <Grid item lg={6} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Generating Profile </b>{' '}
              {forecastList
                .filter((item) => item.id === formData.generating_profile_id)
                .map((item) => (
                  <span key={item.id}>{item.label}</span>
                ))}
            </p>
          </Grid>
        )}
        <Grid item lg={12}>
          <p className={Style.summaryContent}>
            <b>Description </b> {formData.description}{' '}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Project life (years) </b> {formData.project_life_years}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>First year of commercial operation: </b> {formData.first_year_of_commercial_operation}{' '}
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Year of deployment </b> {formData.year_of_deployment}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Max Energy Output to Grid </b> {formData.max_energy_output_to_grid}{' '}
            {formData.max_energy_output_to_grid_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Max Energy Input from Grid </b> {formData.max_energy_input_from_grid}{' '}
            {formData.max_energy_input_from_grid_unit}
          </p>
        </Grid>
        {String(formData.scenario_project_type) === '2' && (
          <Grid item lg={6} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>RE Generator Nameplate Rating </b> {formData.re_generation_nameplate}{' '}
              {formData.max_energy_input_from_grid_unit}
            </p>
          </Grid>
        )}
        {String(formData.scenario_project_type) === '2' && (
          <>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>RE Type</b> {getHybridGenerationTypeLabel(formData.hybrid_generation_type)}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>DC or AC Coupled</b> {getCoupledTypeLabel(formData.coupled_type)}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Max Inverter Capacity Out </b> {formData.max_bess_inverter_capacity_out}{' '}
                {formData.max_energy_input_from_grid_unit}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Max Inverter Capacity In </b> {formData.max_bess_inverter_capacity_in}{' '}
                {formData.max_energy_input_from_grid_unit}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Max VRE Inverter Capacity Out </b> {formData.max_vre_inverter_capacity_out}{' '}
                {formData.max_vre_inverter_capacity_out_unit}
              </p>
            </Grid>
          </>
        )}
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Rated discharge power </b> {formData.rated_discharge_power} {formData.rated_discharge_power_unit}
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Rated charge power </b> {formData.rated_charge_power} {formData.rated_charge_power_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>BOL storage capacity </b> {formData.bol_storage_capacity} {formData.BOL_storage_capacity_unit}
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>VCAS rated MVAr </b> {formData.vcas_rates_mvar} {formData.VCAS_rates_MVAr_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Overload discharge power </b> {formData.overload_discharge_power}{' '}
            {formData.Overload_discharge_power_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Overload charge Power </b> {formData.overload_charge_power} {formData.Overload_charge_Power_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Inverter capacity </b> {formData.inverter_capacity} {formData.inverter_capacity_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Solve Interval Hours </b> {formData.solve_interval_hours}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Window Hours </b> {formData.window_hours}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Sampling Interval </b> {formData.time_res}
          </p>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Grid row gap={12} cssClass={Style.mt40}>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (Reg raise) </b> {formData.reg_raise_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (Reg lower) </b> {formData.reg_lower_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R1) </b> {formData.r1_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R6) </b> {formData.r6_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R60) </b> {formData.r60_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CMarket (R5) </b> {formData.r5_max_enablement} Max Enablement (MW)
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L1) </b> {formData.l1_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L6) </b> {formData.l6_max_enablement} Max Enablement (MW)
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L60) </b> {formData.l60_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L5) </b> {formData.l5_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
      </Grid>
      <br />
      <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
        <Table headers={nmsTableHeader}>
          {formData.loss_factors.map((data) => (
            <TableRow key={data.year}>
              <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{data.nms_valperhour}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
      <div className={Style.mt40}>
        <Grid row gap={12}>
          <Grid item lg={6} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Raise Reg_Utilisation </b> {formData.rreg_utilisation}
            </p>
          </Grid>
          <Grid item lg={6} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Lower Reg_Utilisation </b> {formData.lreg_utilisation}
            </p>
          </Grid>
          <Grid item lg={6} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Inertia_Utilisation </b> {formData.inertia_utilisation}
            </p>
          </Grid>
        </Grid>
      </div>
      <br /> <br /> <br />
      <div className={Style.mt40}>
        {formData.loss_factors.slice(0, 10).map((lossFactor) => (
          <Grid key={lossFactor.operating_year} row gap={12}>
            <Grid item lg={3} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Operating</b> {lossFactor.operating_year} {lossFactor.year}
              </p>
            </Grid>
            <Grid item lg={3} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Generation MLF </b> {lossFactor.generation_mlf}
              </p>
            </Grid>
            <Grid item lg={3} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Load MLF </b> {lossFactor.load_mlf}
              </p>
            </Grid>
          </Grid>
        ))}
      </div>
      <br />
      <br />
      <Grid row gap={0}>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Cap contracting is intended </b> {formData.Cap_contracting_is_intended ? 'Yes' : 'No'}
          </p>
        </Grid>
        <Grid item lg={12} cssClass={Style.pv0}>
          <Table headers={quarterCapVolumeHeaders}>
            {formData.cap_contracts.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q1_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q1_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q1_cap_strike_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q2_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q2_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q2_cap_strike_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q3_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q3_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q3_cap_strike_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q4_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q4_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q4_cap_strike_price}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid row gap={0}>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Any network support services are intended </b>{' '}
            {formData.network_support_services_intended ? 'Yes' : 'No'}
          </p>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Inertia contract </b> {formData.Inertia_contract ? 'Yes' : 'No'}
          </p>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Selected Inertia Constant (MWs/MVA) </b> {formData.selected_inertia_constant}
          </p>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>RoCoF design criteria (Hz/s) </b> {formData.rocof_design_criteria}
          </p>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={inertiaContractHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.contracted_inertia_value}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.contracted_inertia_contract_price}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={nonMarketReservationHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.discharge_power_res_non_market_service}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.charge_power_res_non_market_service}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.energy_res_non_market_service}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={sipsContractHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.sips_reserved_power_capacity}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.sips_reserved_energy_storage_capacity}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.sips_contract_value}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={srasContractHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.sras_reserved_energy_storage_capacity}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.sras_contract_value}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={vcasContractHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.vcas_real_power_capability}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.vcas_contract_value}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Standby Power Consumption </b> {formData.standby_power_consumption} MW
          </p>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={[{ label: ProjectInputLgc.YEAR }, { label: ProjectInputLgc.Defaultwarrantedcycling }]}>
            {formData.commercial_parameters.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.default_warranted_cycling}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={bessExpectedPerformanceHeaders}>
            {formData.bess_inputs.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.energy_discharge_capacity}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.rte}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        {String(formData.scenario_project_type) === '2' && (
          <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
            <Table
              headers={[
                { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
                ...(['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                ['1', '2'].includes(String(formData.coupled_type))
                  ? [
                      { label: BessExpectedPerformanceCyclePerDay.GENERATION_EFFICIENCY },
                      { label: BessExpectedPerformanceCyclePerDay.AC_EFFICIENCY },
                    ]
                  : []),
                ...(['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                String(formData.coupled_type) === '1'
                  ? [{ label: BessExpectedPerformanceCyclePerDay.CROSS_TRANSFER_EFFICIENCY }]
                  : [{ label: BessExpectedPerformanceCyclePerDay.DC_EFFICIENCY }]),
              ]}
            >
              {formData.bess_inputs.map((data) => (
                <TableRow key={data.year}>
                  <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>

                  {['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                    ['1', '2'].includes(String(formData.coupled_type)) && (
                      <>
                        <TableCell cellClass={Style.tableDataPadding}>{data.generation_efficieny}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>{data.ac_charging_efficieny}</TableCell>
                      </>
                    )}

                  {['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                  String(formData.coupled_type) === '1' ? (
                    <TableCell cellClass={Style.tableDataPadding}>{data.transfer_efficieny}</TableCell>
                  ) : (
                    <TableCell cellClass={Style.tableDataPadding}>{data.dc_charging_efficieny}</TableCell>
                  )}
                </TableRow>
              ))}
            </Table>
          </Grid>
        )}
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={projectCommercialParametersHeaders}>
            {CommercialParameters.map((data) => (
              <TableRow key={data.keyName}>
                <TableCell cellClass={Style.tableDataPadding}>{data.label}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{formData[data.keyName]}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table
            headers={
              String(formData.scenario_project_type) === '1' ? capexValueHeaders : capexValueSummaryHeadersHybrid
            }
          >
            {formData.capex.map((data) => (
              <TableRow key={data.year}>
                {String(formData.scenario_project_type) === '1' ? (
                  <>
                    <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.capex_estimate}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.bess_estimate}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.re_generator_estimate}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.common_equipment}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={repexValueHeaders}>
            {formData.repex.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.mid_life_repex}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={4}>
          <p className={Style.summaryContent}>
            <b>LTSA calculation mode </b> {formData.is_manual_ltsa_input ? 'Calculated' : 'Manual'}
          </p>
        </Grid>
        <Grid item lg={4}>
          <p className={Style.summaryContent}>
            <b>Warranty calculation mode </b> {formData.is_manual_warranty_input ? 'Calculated' : 'Manual'}
          </p>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table
            headers={[
              { label: ProjectInputLgc.YEAR },
              ...(String(formData.scenario_project_type) === '2'
                ? [{ label: ProjectInputLgc.BESSLTSA }, { label: ProjectInputLgc.REGLTSA }]
                : []),
              {
                label: getLabel(
                  formData.is_manual_ltsa_input,
                  ProjectInputLgc.LTSA_CALCULATED,
                  ProjectInputLgc.LTSA_MANUAL,
                ),
              },
              {
                label: getLabel(
                  formData.is_manual_warranty_input,
                  ProjectInputLgc.WARRANTY_CALCULATED,
                  ProjectInputLgc.WARRANTY_MANUAL,
                ),
              },
              { label: ProjectInputLgc.TUOS },
              { label: ProjectInputLgc.OTHER },
            ]}
          >
            {formData.commercial_parameters.map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                {String(formData.scenario_project_type) === '2' && (
                  <>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {data.bess_long_term_service_agreement_cost}
                    </TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {data.re_generator_long_term_service_agreement_cost}
                    </TableCell>
                  </>
                )}
                <TableCell cellClass={Style.tableDataPadding}>
                  {formData.is_manual_ltsa_input
                    ? data.long_term_service_agreement_cost_percentage
                    : data.manual_long_term_service_agreement_cost}
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  {formData.is_manual_warranty_input
                    ? data.warranty_cost_percentage
                    : data.manual_warranty_cost_absolute}
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.tuos_cost}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.other_o_and_m_cost}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}
