import {
  Button,
  Container,
  IHeader,
  Pagination,
  Pill,
  Table,
  TableCell,
  TableRow,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide'
import React, { startTransition, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import ForecastActionButtons from 'src/components/ForecastActionButtons'
import TableListFilter from 'src/components/TableListFilter'
import { DEFAULTPAGINATIONDISPLAYPAGES, FORECAST_FILE_STATUS, STATUS_COLOR } from 'src/config/constant'
import { convertformatDate, dateToString } from 'src/helpers/utils'
import {
  createdDateFilterState,
  forecastcurrentPageState,
  forecastDataSelector,
  getAllForecastData,
  inProductedSecretState,
  nameFilterState,
  scenerioProjectIdState,
  selectedProjectIdState,
  yearsFilterState,
} from 'src/stores/AppStore'
import Style from '../../styles/ForecastingList.module.sass'
import { ForecastItem } from 'src/models/api/Forecasting'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { FileTypeEnum } from 'src/enums/ForecastEnum'

const MemoizedForecastActionButtons = React.memo(ForecastActionButtons)
type SortOrder = 'asc' | 'none' | 'desc'

const tableLabels = {
  forecastFileName: 'Forecast File Name',
  period: 'Period',
  description: 'Description',
  createdDate: 'Created Date',
  lastUpdated: 'Last Updated',
  status: 'Status',
  type: 'Type',
  action: 'Action',
}

const ForecastingList = () => {
  const navigate = useNavigate()
  if (appInsights) appInsights.trackPageView({ name: 'ForecastingList' })
  const SecretState = useRecoilValue(inProductedSecretState)
  const [currentPage, setCurrentPage] = useRecoilState(forecastcurrentPageState)
  const setSelectedProjectId = useSetRecoilState(scenerioProjectIdState)
  const projectId = useRecoilValue(selectedProjectIdState)
  const forecastData = useRecoilValue(forecastDataSelector)
  const forecastallData = useRecoilValue(getAllForecastData)
  const [forecastState, setForecastState] = useState({ forecastings: [], totalPages: 1, totalCount: 1 })
  const [sortConfig, setSortConfig] = useState({ key: '', order: 'asc' })

  const [nameFilter, setNameFilter] = useRecoilState(nameFilterState)
  const [yearsFilter, setYearsFilter] = useRecoilState(yearsFilterState)
  const [createdDateFilter, setCreatedDateFilter] = useRecoilState(createdDateFilterState)

  const tableForecastHeaders: IHeader[] = [
    {
      label: tableLabels.forecastFileName,
      sort: sortConfig.key === 'file_name' ? (sortConfig.order as SortOrder) : 'none',
      style: { width: '10%' },
      onSort: () => handleSort('file_name'),
    },
    {
      label: tableLabels.period,
      sort: sortConfig.key === 'period' ? (sortConfig.order as SortOrder) : 'none',
      style: { width: '10%' },
      onSort: () => handleSort('period'),
    },
    {
      label: tableLabels.description,
      style: { width: '20%' },
    },
    {
      label: tableLabels.createdDate,
      sort: sortConfig.key === 'created_at' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('created_at'),
      style: { width: '12%' },
    },
    {
      label: tableLabels.lastUpdated,
      sort: sortConfig.key === 'updated_at' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('updated_at'),
      style: { width: '12%' },
    },
    {
      label: tableLabels.status,
      sort: sortConfig.key === 'file_processing_status.name' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('file_processing_status.name'),
      style: { width: '12%' },
    },
    {
      label: tableLabels.type,
      sort: sortConfig.key === 'profile_type' ? (sortConfig.order as SortOrder) : 'none',
      onSort: () => handleSort('profile_type'),
      style: { width: '10%' },
    },
    { label: tableLabels.action, style: { width: '15%' } },
  ]

  useEffect(() => {
    setSelectedProjectId(projectId)
    setCurrentPage(1)
    startTransition(() => {
      if (forecastData?.['items']) {
        setForecastState({
          forecastings: forecastData?.['items'],
          totalPages: forecastData?.['pages'],
          totalCount: forecastData?.['total'],
        })
      } else {
        setForecastState({ forecastings: [], totalPages: 1, totalCount: 1 })
      }
    })
  }, [forecastData, projectId, setCurrentPage, setSelectedProjectId])

  const handleShowUpload = useCallback(() => {
    navigate(`/dashboard/${projectId}/forecastingupload`)
  }, [navigate, projectId])

  const handleUploadGenerationProfile = useCallback(() => {
    navigate(`/dashboard/${projectId}/forecastinguploadprofile`)
  }, [navigate, projectId])

  const handlePaginationClick = useCallback(
    (page: number) => {
      setCurrentPage(page === 0 ? 1 : page)
    },
    [setCurrentPage],
  )

  const handleSort = useCallback((key: string) => {
    setSortConfig((prevConfig) => {
      const newOrder = prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc'
      return { key, order: newOrder }
    })
  }, [])

  const getStatusDisplayName = useCallback((status: string): string => {
    return FORECAST_FILE_STATUS[status] || status
  }, [])

  const renderStatusCell = useCallback(
    (status: string) => {
      const displayStatus = getStatusDisplayName(status)
      return (
        <Pill colour={5} cssClass={Style.flex} style={{ backgroundColor: STATUS_COLOR[displayStatus] }} size='medium'>
          {displayStatus}
        </Pill>
      )
    },
    [getStatusDisplayName],
  )

  const sortedForecastings = useMemo(() => {
    const sorted = [...forecastState.forecastings].sort((a, b) => {
      let itemAValue, itemBValue

      if (sortConfig.key === 'file_processing_status.name') {
        itemAValue = a['file_processing_status']['name']
        itemBValue = b['file_processing_status']['name']
      } else {
        itemAValue = a[sortConfig.key]
        itemBValue = b[sortConfig.key]
      }

      if (sortConfig.order === 'asc') {
        if (itemAValue < itemBValue) return -1
        if (itemAValue > itemBValue) return 1
        return 0
      } else {
        if (itemAValue > itemBValue) return -1
        if (itemAValue < itemBValue) return 1
        return 0
      }
    })
    return sorted
  }, [forecastState.forecastings, sortConfig])

  const removeNameFilterItem = (id: number) => {
    setNameFilter((prev: string[]) => {
      const updatedFilters = prev.filter((filterId) => filterId !== id.toString())
      return updatedFilters
    })
  }
  const isFilterApplied =
    nameFilter.length > 0 || yearsFilter || (createdDateFilter.startDate && createdDateFilter.endDate)

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>Forecast Data</h1>
            {SecretState && !isRoleEmpty(SecretState.role) && (
              <div className={Style.btnContainer}>
                <Button
                  type='custom'
                  label='Upload Generation Profile'
                  onClick={handleUploadGenerationProfile}
                  cssClass={Style.btnUploadGenerationProfile}
                />
                <Button
                  type='custom'
                  label='Upload New Forecast'
                  onClick={handleShowUpload}
                  cssClass={Style.btnNewProject}
                />
              </div>
            )}
          </div>
        </Container>
      </header>
      <div className={Style.marginBtm}>
        <section>
          <TableListFilter
            nameFilter={nameFilter}
            yearsFilter={yearsFilter}
            createdDateFilter={createdDateFilter}
            setNameFilter={setNameFilter}
            setYearsFilter={setYearsFilter}
            setCreatedDateFilter={setCreatedDateFilter}
          />

          <Container fluid>
            <div className={isFilterApplied ? Style.bottomBorder : ''}>
              {nameFilter.length > 0 && (
                <span className={Style.customTextColor}>
                  Forecast File Name
                  {Array.isArray(forecastallData) &&
                    forecastallData?.map(
                      (item: ForecastItem) =>
                        nameFilter.includes(item.id.toString()) && (
                          <Pill
                            cssClass={Style.marginXY}
                            colour={12}
                            key={item.file_name}
                            onClose={() => removeNameFilterItem(item.id)}
                            closeTitle='Close'
                          >
                            {item.file_name}
                          </Pill>
                        ),
                    )}
                </span>
              )}
              {yearsFilter && (
                <span className={Style.customTextColor}>
                  No of years
                  <Pill cssClass={Style.marginXY} colour={12} onClose={() => setYearsFilter('')} closeTitle='Close'>
                    {yearsFilter}
                  </Pill>
                </span>
              )}
              {createdDateFilter.startDate && createdDateFilter.endDate && (
                <span className={Style.customDateTextColor}>
                  Updated Date
                  <Pill
                    cssClass={Style.marginDateXY}
                    colour={12}
                    onClose={() => setCreatedDateFilter({ startDate: null, endDate: null })}
                    closeTitle='Close'
                  >
                    {convertformatDate(createdDateFilter.startDate) +
                      ' - ' +
                      convertformatDate(createdDateFilter.endDate)}
                  </Pill>
                </span>
              )}
            </div>
          </Container>
          <Container fluid cssClass={Style.paddingX}>
            <div className={Style.overflowx}>
              <Table cssClass={Style.tableAlignment} headers={tableForecastHeaders}>
                {sortedForecastings.length > 0 ? (
                  sortedForecastings.map((item) => (
                    <TableRow key={item['id']}>
                      <TableCell align='left'>{item['file_name']}</TableCell>
                      <TableCell align='left' sortableHeader>
                        {item['period']}
                      </TableCell>
                      <TableCell align='left' cellClass={Style.forecastdesc}>
                        <Tooltip
                          show={item['description']}
                          offset={{
                            left: 0,
                            top: 0,
                          }}
                        >
                          <div
                            className={Style.tooltipDescription}
                            style={{
                              width: '300px',
                              display: 'inline-block',
                            }}
                          >
                            {item['description']}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='left'>{dateToString(item['created_at'])}</TableCell>
                      <TableCell align='left'>{dateToString(item['updated_at'])}</TableCell>
                      <TableCell align='left' cellClass={Style.statusName}>
                        {renderStatusCell(item['file_processing_status']['name'])}
                      </TableCell>
                      <TableCell align='left'>
                        {' '}
                        <span className={Style.paCellBg}>
                          {item['file_type'] ? FileTypeEnum[item['file_type']] : '-'}
                        </span>
                      </TableCell>
                      <TableCell>
                        <MemoizedForecastActionButtons
                          forecastId={item['id']}
                          status={item['file_processing_status']['name']}
                          fileType={item['file_type']}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </div>
            <div className={Style.paginationCont}>
              <div className={Style.paginationButtons}>
                <Pagination
                  page={currentPage}
                  pageCount={forecastState.totalPages}
                  displayPages={DEFAULTPAGINATIONDISPLAYPAGES}
                  onChange={handlePaginationClick}
                  prevButtonTitle='Previous page'
                  nextButtonTitle='Next page'
                  cssClass={Style.paginatCont}
                />
              </div>
              <div className={Style.totalPageCount}>
                {currentPage} - {forecastState.totalPages} of {forecastState.totalCount}
              </div>
            </div>
          </Container>
        </section>
      </div>
    </Container>
  )
}

export default ForecastingList
