import { ChartData, ChartOptions } from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'

interface AverageEnergyChartProps {
  chartsData: ChartData<'line'>[]
}

const AverageEnergyChart: React.FC<AverageEnergyChartProps> = ({ chartsData }) => {
  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: 'Hour of the Day',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Average Dispatch (MW)',
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  const combinedLegendItems = React.useMemo(() => {
    const items = new Map<string, string>()

    chartsData.forEach((chartData) => {
      chartData.datasets.forEach((dataset) => {
        items.set(dataset.label as string, dataset.borderColor as string)
      })
    })

    return Array.from(items.entries()).map(([label, color]) => ({ text: label, strokeStyle: color }))
  }, [chartsData])

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {chartsData.map((chartData) => (
          <div key={chartData.datasets[0].label} style={{ flex: '1 1 50%', padding: '10px', boxSizing: 'border-box' }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        ))}
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        {combinedLegendItems.map((item) => (
          <div key={`legend_${item.text}`} style={{ display: 'inline-block', margin: '0 10px' }}>
            <span
              style={{
                display: 'inline-block',
                width: '15px',
                height: '2px',
                backgroundColor: item.strokeStyle,
                marginBottom: '5px',
                marginRight: '8px',
              }}
            ></span>
            {item.text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AverageEnergyChart
