import {
  Accordion,
  AccordionPanel,
  Grid,
  FormInput,
  Table,
  TableRow,
  TableCell,
  InfoTooltip,
} from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect, useState } from 'react'
import { ScenarioFormData } from 'src/models/ScenarioFormData'
import { MarginalLossFactor } from 'src/models/api/CreateScenarioRequestModel'
import Style from '../../styles/CreateScenario.module.sass'
import { LossFactoryTableHeader } from 'src/enums/ScenarioEnums'
import { getCurrentDateISOString } from 'src/helpers/utils'
import CustomTable from './CustomTable'

interface IHeader {
  label: string
  key: string
}

const LossFactoryheader = [
  { label: LossFactoryTableHeader.OPERATING_YEAR },
  { label: LossFactoryTableHeader.GENERATION_MLF },
  { label: LossFactoryTableHeader.LOAD_MLF },
]

const NMSheader: IHeader[] = [
  { label: LossFactoryTableHeader.OPERATING_YEAR, key: 'operating_year' },
  { label: LossFactoryTableHeader.NMsValue, key: 'nms_valperhour' },
]

interface Step2Props {
  formData: ScenarioFormData
  setFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors: Record<string, string>
}

export const Step2: React.FC<Step2Props> = ({ formData, setFormData, errors }) => {
  const [selected, setSelected] = useState<string[]>(['p1', 'p2', 'p3', 'p4', 'p5'])

  const togglePanel = (panel: string) => {
    const index = selected.indexOf(panel)
    if (index === -1) {
      setSelected((sel) => [...sel, panel])
    } else {
      const newSel = [...selected]
      newSel.splice(index, 1)
      setSelected(newSel)
    }
  }
  const updateFormData = (name: string, value: string | number | boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }
  const handleMarketElasticFactors = (value: string | number | boolean, name: string) => {
    updateFormData(name, value)
  }

  const handleChange = (value: string | number, name: string) => {
    updateFormData(name, value)
  }
  const handleMarginalLossFactors = (id: number, field: string, value: string) => {
    setFormData((prevFormData) => {
      const updatedLossFactors = prevFormData.loss_factors.map((factor) => {
        if (factor.operating_year === id) {
          return {
            ...factor,
            [field]: value,
          }
        }
        return factor
      })

      return {
        ...prevFormData,
        loss_factors: updatedLossFactors,
      }
    })
  }

  const addOrRemoveFactors = useCallback(() => {
    const numberOfObjectsToAdd = Number(formData.project_life_years) + 1
    const currentLossFactorsLength = formData.loss_factors.length

    if (!isNaN(numberOfObjectsToAdd)) {
      const updatedLossFactors = formData.loss_factors.map((factor, index) => {
        const newYear = Number(formData.first_year_of_commercial_operation) + index
        return {
          ...factor,
          year: newYear,
        }
      })

      for (let i = currentLossFactorsLength; i < numberOfObjectsToAdd; i++) {
        const newYear = Number(formData.first_year_of_commercial_operation) + i
        const newFactorsObj: MarginalLossFactor = {
          operating_year: i,
          year: newYear,
          generation_mlf: '0',
          load_mlf: '0',
          created_at: getCurrentDateISOString(),
          updated_at: getCurrentDateISOString(),
          created_by: getCurrentDateISOString(),
          updated_by: getCurrentDateISOString(),
          nms_valperhour: '',
        }
        updatedLossFactors.push(newFactorsObj)
      }

      const shouldUpdate =
        updatedLossFactors.length !== formData.loss_factors.length ||
        updatedLossFactors.some((factor, index) => factor.year !== formData.loss_factors[index]?.year)

      if (shouldUpdate) {
        setFormData((prevData) => ({
          ...prevData,
          loss_factors: updatedLossFactors.slice(0, numberOfObjectsToAdd),
        }))
      }
    }
  }, [formData.project_life_years, formData.first_year_of_commercial_operation, formData.loss_factors, setFormData])

  useEffect(() => {
    addOrRemoveFactors()
  }, [formData.first_year_of_commercial_operation, addOrRemoveFactors])

  const rows = formData.loss_factors.map((data) => (
    <TableRow key={data.operating_year}>
      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
      <TableCell cellClass={Style.tableDataPadding}>
        <FormInput
          type='text'
          label=''
          placeholder=''
          value={data.nms_valperhour.toString()}
          onChange={(v) => handleMarginalLossFactors(data.operating_year, 'nms_valperhour', v)}
          default={!data.nms_valperhour}
          error={errors[`loss_factors[${data.operating_year - 1}].nms_valperhour`]}
        />
      </TableCell>
    </TableRow>
  ))

  return (
    <div className='mainContainerBlock'>
      <h2>Market inputs</h2>
      <Accordion onPanelToggle={togglePanel} activePanelIds={selected}>
        <AccordionPanel
          panelId='p1'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>C-FCAS max registered capacity</h3>
              <div className={Style.p1Note}>Note: 100% of rated capacity in each market is AEMO default guideline</div>
            </div>
          }
        >
          <Grid row gap={12}>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (R1)'}
                placeholder={''}
                value={formData.r1_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r1_max_registered_capacity')
                }}
                error={errors.r1_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (R6)'}
                placeholder={''}
                value={formData.r6_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r6_max_registered_capacity')
                }}
                error={errors.r6_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (R60)'}
                placeholder={''}
                value={formData.r60_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r60_max_registered_capacity')
                }}
                error={errors.r60_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (R5)'}
                placeholder={''}
                value={formData.r5_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r5_max_registered_capacity')
                }}
                error={errors.r5_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (L1)'}
                placeholder={''}
                value={formData.l1_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l1_max_registered_capacity')
                }}
                error={errors.l1_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (L6)'}
                placeholder={''}
                value={formData.l6_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l6_max_registered_capacity')
                }}
                error={errors.l6_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (L60)'}
                placeholder={''}
                value={formData.l60_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l60_max_registered_capacity')
                }}
                error={errors.l60_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'CFCAS Market (L5)'}
                placeholder={''}
                value={formData.l5_max_registered_capacity?.toString()}
                required={true}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l5_max_registered_capacity')
                }}
                error={errors.l5_max_registered_capacity}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>% of rated capacity</p>
            </Grid>
          </Grid>
        </AccordionPanel>

        <AccordionPanel
          panelId='p2'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>FCAS maximum enablements</h3>
              <div className={Style.p1Note}>
                Note: Due to the relatively low FCAS volumes procured by AEMO, it is not necessarily realistic to
                consider the maximum registered capacities as able to be regularly dispatched. The model therefore
                constrains the maximum FCAS volumes modelled to be dispatched from any single project. Aurecon's default
                values are based on no single project being enabled for more than 20% of total average NEM volumes
                procured (based on avg FY 21/22 volumes)
              </div>
            </div>
          }
        >
          <Grid row gap={12}>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'Market (Reg raise)'}
                placeholder={''}
                value={formData.reg_raise_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'reg_raise_max_enablement')
                }}
                required={true}
                error={errors.reg_raise_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>

            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'Market (Reg lower)'}
                placeholder={''}
                value={formData.reg_lower_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'reg_lower_max_enablement')
                }}
                required={true}
                error={errors.reg_lower_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                cssClass='removeAsterisk'
                label={'Market (R1)'}
                placeholder={''}
                value={formData.r1_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r1_max_enablement')
                }}
                required={true}
                error={errors.r1_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (R6)'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.r6_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r6_max_enablement')
                }}
                required={true}
                error={errors.r6_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (R60)'}
                placeholder={''}
                cssClass='removeAsterisk'
                value={formData.r60_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r60_max_enablement')
                }}
                required={true}
                error={errors.r60_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (R5)'}
                placeholder={''}
                cssClass='removeAsterisk'
                value={formData.r5_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'r5_max_enablement')
                }}
                required={true}
                error={errors.r5_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (L1)'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.l1_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l1_max_enablement')
                }}
                required={true}
                error={errors.l1_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (L6)'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.l6_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l6_max_enablement')
                }}
                required={true}
                error={errors.l6_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (L60)'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.l60_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l60_max_enablement')
                }}
                required={true}
                error={errors.l60_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Market (L5)'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.l5_max_enablement?.toString()}
                onChange={(v) => {
                  handleMarketElasticFactors(v, 'l5_max_enablement')
                }}
                error={errors.l5_max_enablement}
              />
            </Grid>
            <Grid item lg={2}>
              <p className={Style.unitSubText}>Max Enablement (MW)</p>
            </Grid>
          </Grid>
        </AccordionPanel>

        <AccordionPanel
          panelId='p3'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>NMS Value Per Hour</h3>
            </div>
          }
        >
          <CustomTable headers={NMSheader} rows={rows} />
        </AccordionPanel>
        <AccordionPanel
          panelId='p4'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>
                Utilisation parameters{' '}
                <InfoTooltip show='Assumed energy utilisation parameters (average MW per MW.s)' />
              </h3>
            </div>
          }
        >
          <Grid row gap={12}>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Raise Reg_Utilisation'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.rreg_utilisation.toString()}
                onChange={(v) => {
                  handleChange(v, 'rreg_utilisation')
                }}
                required={true}
                error={errors.rreg_utilisation}
              />
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Lower Reg_Utilisation'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.lreg_utilisation.toString()}
                onChange={(v) => {
                  handleChange(v, 'lreg_utilisation')
                }}
                required={true}
                error={errors.lreg_utilisation}
              />
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Inertia_Utilisation'}
                cssClass='removeAsterisk'
                placeholder={''}
                value={formData.inertia_utilisation.toString()}
                onChange={(v) => {
                  handleChange(v, 'inertia_utilisation')
                }}
                required={true}
                error={errors.inertia_utilisation}
              />
            </Grid>
          </Grid>
        </AccordionPanel>
        <AccordionPanel
          panelId='p5'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>Marginal loss factors </h3>
            </div>
          }
        >
          <Table headers={LossFactoryheader}>
            {formData.loss_factors.map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={'Value must be between 0 and 1.5'}
                    value={data.generation_mlf.toString()}
                    onChange={(v) => handleMarginalLossFactors(data.operating_year, 'generation_mlf', v)}
                    default={!data.generation_mlf}
                    error={errors[`loss_factors[${data.operating_year - 1}].generation_mlf`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={'Value must be between 0 and 1.5'}
                    value={data.load_mlf.toString()}
                    onChange={(v) => handleMarginalLossFactors(data.operating_year, 'load_mlf', v)}
                    default={!data.load_mlf}
                    error={errors[`loss_factors[${data.operating_year - 1}].load_mlf`]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </AccordionPanel>
      </Accordion>
    </div>
  )
}
