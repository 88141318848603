import {
  Accordion,
  AccordionPanel,
  Button,
  Container,
  Grid,
  Table,
  TableCell,
  TableRow,
  Text,
} from '@aurecon-creative-technologies/styleguide'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { fetchExecutionsDetails } from 'src/api/ExecutionsService'
import { downloadForecastFile, fetchTablePreviewData } from 'src/api/ForecastService'
import { QuarterCapVolume } from 'src/enums/ScenarioEnums'
import {
  CapContract,
  ExecutionsDetailsResponseModel,
  MarginalLossFactor,
  MarketInput,
  MessageJson,
} from 'src/models/api/ExecutionsDetailsResponseModel'
import { ForecastingTableModelItem } from 'src/models/api/ForecastingTableModel'
import { IResponse } from 'src/models/api/IResponse'
import { inProductedSecretState, selectedProjectIdState } from 'src/stores/AppStore'
import Style from '../../styles/Execution.module.sass'
import TablePreview from '../Forecasting/TablePreview'
import { isRoleEmpty } from 'src/helpers/appRoles'

const quarterExecutionsCapVolumeHeaders = [
  { label: QuarterCapVolume.YEAR },
  { label: QuarterCapVolume.Q1_CAP_VOLUME },
  { label: QuarterCapVolume.Q1_CAP_PRICE },
  { label: QuarterCapVolume.Q2_CAP_VOLUME },
  { label: QuarterCapVolume.Q2_CAP_PRICE },
  { label: QuarterCapVolume.Q3_CAP_VOLUME },
  { label: QuarterCapVolume.Q3_CAP_PRICE },
  { label: QuarterCapVolume.Q4_CAP_VOLUME },
  { label: QuarterCapVolume.Q4_CAP_PRICE },
]

const ExecutionsDetail = () => {
  const { scenario_id = '' } = useParams<{ scenario_id: string }>()
  const navigate = useNavigate()
  const projectId = useRecoilValue(selectedProjectIdState)
  const SecretState = useRecoilValue(inProductedSecretState)
  const [executionsDetailsData, setExecutionsDetailsData] = useState<IResponse<ExecutionsDetailsResponseModel> | null>(
    null,
  )

  const [executionsMessageJSON, setExecutionsMessageJSON] = useState<MessageJson | null>(null)
  const [executionsMarketInput, setExecutionsMarketInput] = useState<MarketInput | null>(null)
  const [executionsMLFData, setExecutionsMLFData] = useState<MarginalLossFactor[] | null>(null)
  const [tableData, setTableData] = useState<ForecastingTableModelItem[] | null>(null)
  const [selected, setSelected] = useState('')
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)
  const [scenario_forecast_id, setScenario_forecast_id] = useState<number | null>(null)

  const togglePanel = (panel: string) => {
    setSelected(panel === selected ? '' : panel)
  }

  const handleDownloadClick = async () => {
    try {
      if (scenario_forecast_id) {
        await downloadForecastFile(scenario_forecast_id, (progress) => {
          setDownloadProgress(progress)
        })
        setDownloadProgress(null)
      }
    } catch (error) {
      console.error('Failed to download forecast:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const handleGoBack = () => {
    navigate(`/dashboard/${projectId}/executionlist`)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchExecutionsDetails(Number(scenario_id))
        if (response) {
          setExecutionsDetailsData(response)
          const messageJSON = response?.['message_json']
          setExecutionsMessageJSON(messageJSON)
          if (messageJSON?.scenario_data?.scenario_detail) {
            setExecutionsMarketInput(messageJSON.scenario_data?.scenario_detail?.market_inputs[0])
            setExecutionsMLFData(messageJSON.scenario_data?.scenario_detail?.marginal_loss_factors)
          }
          const forecastDataId = response?.['scenario'].forecast_data.id
          setScenario_forecast_id(forecastDataId)
          if (forecastDataId) {
            const fetchResponse = await fetchTablePreviewData(forecastDataId)
            if (fetchResponse) {
              setTableData(fetchResponse)
            }
          }
        }
      } catch (error) {
        console.error('Error fetching execution details:', error)
      }
    }

    fetchData()
  }, [scenario_id, scenario_forecast_id])

  useEffect(() => {
    if (appInsights) appInsights.trackPageView({ name: 'ExecutionsDetail' })
  }, [])
  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <div className={Style.go_back}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
                onClick={handleGoBack}
              />
              <h2>Scenarios - {executionsDetailsData?.['scenario']?.['name']}</h2>
            </div>
            {SecretState && !isRoleEmpty(SecretState.role) && (
              <Button
                type='custom'
                cssClass={Style.newExecBtn}
                label='Download Forecast Files'
                onClick={handleDownloadClick}
              />
            )}
          </div>
        </Container>
      </header>
      <div className={Style.marginBtm}>
        <section className={Style.detail_wrapper}>
          <Container fluid cssClass={Style.paddingX}>
            <Grid row gap={6}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={Style.grid_cell}>
                  <h4>Scenario name</h4>
                  <p>{executionsDetailsData?.['scenario']?.['name']}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={Style.grid_cell}>
                  <h4>Scenario ID</h4>
                  <p>{executionsDetailsData?.['scenario']?.['id']}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={Style.grid_cell}>
                  <h4>Description</h4>
                  <p>{executionsDetailsData?.['scenario']?.['description']}</p>
                </div>
              </Grid>
            </Grid>
            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Project life (years) </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.project_life_years}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>First year of commercial operation </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.first_year_of_commercial_operation}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Max Energy Output to Grid </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.max_energy_output_to_grid} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Max Energy Output from Grid </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.max_energy_input_from_grid} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Rated discharge power </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.rated_discharge_power} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Rated charge power </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.rated_charge_power} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>BOL storage capacity </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.bol_storage_capacity} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>VCAS rated MVAr </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.vcas_rates_mvar} MVAr</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Overload discharge power </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.overload_discharge_power} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Overload charge Power </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.overload_charge_power} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Inverter capacity </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.inverter_capacity} MVA</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Solve Interval Hours </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.solve_interval_hours}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Window Hours </h4> <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.window_hours}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Sampling Interval </h4> <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.time_res}</p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Use client nominated market elasticity factors? </h4>
                    <p>{executionsMarketInput?.is_client_nominated_mef_avail}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Client nominated market elasticity factors </h4>
                    <p>{executionsMarketInput?.is_client_nominated_mef_avail}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R1) </h4>
                    <p>{executionsMarketInput?.r1_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R6) </h4>
                    <p>{executionsMarketInput?.r6_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R60) </h4>
                    <p>{executionsMarketInput?.r60_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R5) </h4>
                    <p>{executionsMarketInput?.r5_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L1) </h4>
                    <p>{executionsMarketInput?.l1_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L6) </h4>
                    <p>{executionsMarketInput?.l6_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L60) </h4>
                    <p>{executionsMarketInput?.l60_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L5) </h4>
                    <p>{executionsMarketInput?.l5_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (Reg raise) </h4>
                    <p>{executionsMarketInput?.reg_raise_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (Reg lower) </h4>
                    <p>{executionsMarketInput?.reg_lower_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R1) </h4>
                    <p>{executionsMarketInput?.r1_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R6) </h4>
                    <p>{executionsMarketInput?.r6_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R60) </h4>
                    <p>{executionsMarketInput?.r60_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R5) </h4>
                    <p>{executionsMarketInput?.r5_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L1) </h4>
                    <p>{executionsMarketInput?.l1_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L6) </h4>
                    <p>{executionsMarketInput?.l6_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L60) </h4>
                    <p>{executionsMarketInput?.l60_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L5) </h4>
                    <p>{executionsMarketInput?.l5_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              {executionsMLFData?.map((item) => (
                <React.Fragment key={item?.id}>
                  <Grid row gap={6}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <div className={Style.inline_grid_cell}>
                        <h4>Operating</h4>
                        <p>{item?.operating_year}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <div className={Style.inline_grid_cell}>
                        <h4>Generation MLF</h4>
                        <p>{item?.generation_mlf}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <div className={Style.inline_grid_cell}>
                        <h4>Load MLF</h4>
                        <p>{item?.load_mlf}</p>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </section>

            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Cap contracting is intended </h4>
                    <p>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.is_cap_contract_intended ? 'Yes' : 'No'}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <div className={Style.tbl_container}>
                <Table headers={quarterExecutionsCapVolumeHeaders}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.cap_contracts.map((item: CapContract) => (
                    <TableRow key={item.id}>
                      <TableCell align='center'>{item.year}</TableCell>
                      <TableCell align='center'>{item.q1_cap_price}</TableCell>
                      <TableCell align='center'>{item.q1_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q2_cap_price}</TableCell>
                      <TableCell align='center'>{item.q2_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q3_cap_price}</TableCell>
                      <TableCell align='center'>{item.q3_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q4_cap_price}</TableCell>
                      <TableCell align='center'>{item.q4_cap_volume}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </div>

              <Accordion activePanelIds={selected} onPanelToggle={togglePanel}>
                <AccordionPanel
                  cssHeaderClass={Style.expandMore}
                  panelId='p1'
                  label={<Text cssClass={Style.txtLabel}>Forecast file preview</Text>}
                >
                  {tableData && <TablePreview data={tableData} />}
                </AccordionPanel>
              </Accordion>
            </section>
          </Container>
        </section>
      </div>
    </Container>
  )
}

export default ExecutionsDetail
