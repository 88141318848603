import { ChartData } from 'chart.js'
import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { AverageDailyEnergyDispatchChart } from 'src/models/api/ReportMappingResponseModel'
import AverageEnergyChart from './AverageEnergyChart'

interface ProcessAverageDailyEnergyDisaptchChartProps {
  data: AverageDailyEnergyDispatchChart[]
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessAverageDailyEnergyDisaptchChart: React.FC<ProcessAverageDailyEnergyDisaptchChartProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const parsedData = useMemo(
    () =>
      data.map((item) => {
        const hourlyData = JSON.parse(item.data)
        const hours = Object.keys(hourlyData)
        const prices = hours.map((hour) => ({
          hour,
          Autumn: hourlyData[hour].Autumn,
          Spring: hourlyData[hour].Spring,
          Summer: hourlyData[hour].Summer,
          Winter: hourlyData[hour].Winter,
        }))

        return {
          year: item.year,
          prices,
        }
      }),
    [data],
  )

  const formatChartData = (yearData: {
    year: number
    prices: { hour: string; Autumn: number; Spring: number; Summer: number; Winter: number }[]
  }): ChartData<'line'> => {
    const labels = yearData.prices.map((entry) => entry.hour)

    return {
      labels,
      datasets: [
        {
          label: `Autumn`,
          data: yearData.prices.map((entry) => entry.Autumn),
          fill: false,
          borderColor: '#FFC702',
          borderWidth: 1,
          pointRadius: 0,
          showLine: true,
        },
        {
          label: `Spring`,
          data: yearData.prices.map((entry) => entry.Spring),
          fill: false,
          borderColor: '#AFDA90',
          borderWidth: 1,
          pointRadius: 0,
          showLine: true,
        },
        {
          label: `Summer`,
          data: yearData.prices.map((entry) => entry.Summer),
          fill: false,
          borderColor: '#D57883',
          borderWidth: 1,
          pointRadius: 0,
          showLine: true,
        },
        {
          label: `Winter`,
          data: yearData.prices.map((entry) => entry.Winter),
          fill: false,
          borderColor: '#2CA5DB',
          borderWidth: 1,
          pointRadius: 0,
          showLine: true,
        },
      ],
    }
  }

  const chartsAverageData = useMemo(() => {
    return parsedData.map((yearData) => formatChartData(yearData))
  }, [parsedData])

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId)
            ? { ...section, chart_data: JSON.stringify(chartsAverageData) }
            : section,
        ),
      },
    }))
  }, [chartsAverageData, setFormData, sectionId])

  return <AverageEnergyChart chartsData={chartsAverageData} />
}

export default ProcessAverageDailyEnergyDisaptchChart
